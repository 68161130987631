<template>
	<section class="license">
		<div class="container">
			<Breadcrumb :pages="pages" class="license__crumb" />

			<h1 class="license__title">
				{{ $t('license.privacy_title') }}
			</h1>
			<div class="license__items">
				<div class="license__item" v-html="$t('license.privacy_text')" />
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { setCommonMetaData } from '@/utils/setMetaData'
import { i18n } from '@/i18n'

export default {
	name: 'Privacy',
	components: { Breadcrumb },
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.privacy')} - OWRealty`, {
			description: i18n.global.t('meta.privacyDesc'),
			type: 'article',
			title: i18n.global.t('meta.privacyTitle'),
			url: window.location.href
		})
	},
	computed: {
		pages() {
			return {
				first: {
					title: this.$t('general.home'),
					srcName: 'MainRoute'
				},
				current: this.$t('other.privacyPolicy')
			}
		},
		content() {
			return this.$t('other.policyContent', { link: window?.location.origin })
		}
	}
}
</script>
